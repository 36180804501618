import { observer } from 'mobx-react';
import React from 'react';
import { MainSubStructure, NormalProgrammeComponent } from '../../utils';
import Add from './components/add';
import styles from './index.less';
import AuthorityManagementModel from './model';

@observer
export default class AuthorityManagement extends React.Component {
  constructor(prop) {
    super(prop);
    this.store = new AuthorityManagementModel();
  }

  public readonly store: AuthorityManagementModel;

  render() {
    const { addModel, normalProgramme, mainSubStructureModel } = this.store;
    return (
      <div className={styles.page}>
        <NormalProgrammeComponent store={normalProgramme} />
        <div className={styles.mainSubStructure}>
          <MainSubStructure store={mainSubStructureModel} />
        </div>
        <Add store={addModel} />
      </div>
    );
  }
}
