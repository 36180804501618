import type { FormInstance } from 'antd';
import { message } from 'antd';
import { action, computed, observable, toJS } from 'mobx';
import React from 'react';
import type { BaseData } from '../../../../utils';
import { request } from '../../../../utils';
import type ParentStore from '../../model';

export default class AddModel {
  constructor(parentStore: ParentStore) {
    this.parentStore = parentStore;
    this.getAllList();
  }

  public parentStore: ParentStore;

  @observable public visible = false;

  @observable public id: string = undefined;

  @observable public data = [];
  @observable public roleType: number;

  @observable public selectDataIndex = [];

  @observable public rpm = {};

  public formRef = React.createRef<FormInstance>();

  @action
  public getAllList = async () => {
    const searchParams = new URLSearchParams(window.location.search);

    const res = await request<BaseData<any[]>>({
      method: 'GET',
      url: '/resource/resource/all',
      params: { sysType: searchParams.get('sysType') },
    });
    this.data = res.data || [];
    if (res?.data?.length && res?.data[0]?.children?.length) {
      this.selectDataIndex = [0, 0];
    }
  };

  @action
  public onCancel = () => {
    this.visible = false;
    this.id = undefined;
    this.roleType = undefined;
    this.rpm = {};
    if (this?.data?.length && this?.data[0]?.children?.length) {
      this.selectDataIndex = [0, 0];
    }
    this.formRef?.current?.resetFields();
  };

  @action
  public onSave = async () => {
    try {
      const params = await this.formRef.current.validateFields();
      const rpm = {};
      Object.keys(this.rpm).forEach((key) => {
        rpm[key] = this.rpm[key]?.join(',');
      });
      const searchParams = new URLSearchParams(window.location.search);
      await request({
        method: 'POST',
        url: this.id ? '/resource/role/update' : '/resource/role/create',
        data: {
          ...params,
          rpm,
          roleId: this.id,
          sysType: searchParams.get('sysType'),
        },
      });
      this.parentStore.mainSubStructureModel.onQuery();
      message.success('操作成功');
      this.onCancel();
    } catch (e) {
      console.error(e);
    }
  };

  @action
  public setSelectDataIndex = (data, index) => {
    this.selectDataIndex[index] = data;
    console.log();
  };

  @computed
  public get getPermission(): any[] {
    let permission = {};
    if (this.data[this.selectDataIndex[0]] && this.data[this.selectDataIndex[0]].children[this.selectDataIndex[1]]) {
      permission = this.data[this.selectDataIndex[0]].children[this.selectDataIndex[1]].permission;
    }
    const newData = [];
    Object.keys(permission).forEach((key) => {
      newData.push({
        label: permission[key],
        value: key,
      });
    });
    return newData || [];
  }

  @action
  public getFirstChecked = (row: any, type: string) => {
    const keys = Object.keys(this.rpm);
    let num = 0;
    row.children.forEach((item: any) => {
      if (keys.includes(item.resourceId)) {
        num += 1;
      }
    });
    if (type === 'checked') {
      return row.children.length === num;
    }
    return Boolean(num);
  };

  @action
  public getSecondLevelChecked = (row: any, type: string) => {
    const keys = Object.keys(this.rpm);
    let num = 0;
    if (keys.includes(row.resourceId)) {
      num = Object.keys(row.permission).length;
    }
    if (type === 'checked') {
      return keys.includes(row.resourceId) && this.rpm[row.resourceId]?.length === num;
    }
    return keys.includes(row.resourceId) && Boolean(this.rpm[row.resourceId]?.length);
  };

  @action
  public onSelectFirst = (row, checked) => {
    if (checked) {
      const _rpm = {};
      row.children.forEach((item: any) => {
        _rpm[item.resourceId] = Object.keys(item.permission);
      });
      this.rpm = {
        ...this.rpm,
        ..._rpm,
      };
      return;
    }
    const resourceIdList = row.children.map((item: any) => item.resourceId);
    this.rpm = Object.keys(this.rpm)
      .filter((key) => !resourceIdList.includes(key))
      .reduce((obj, key) => {
        obj[key] = this.rpm[key];
        return obj;
      }, {});
  };

  @action
  public onSelectSecondLevel = (row, checked) => {
    if (checked) {
      const _rpm = {};
      _rpm[row.resourceId] = Object.keys(row.permission);
      this.rpm = {
        ...this.rpm,
        ..._rpm,
      };
      return;
    }
    const resourceIdList = [row.resourceId];
    this.rpm = Object.keys(this.rpm)
      .filter((key) => !resourceIdList.includes(key))
      .reduce((obj, key) => {
        obj[key] = this.rpm[key];
        return obj;
      }, {});
  };

  @computed
  public get getCheckboxGroupValue() {
    let data = [];
    const [first, secondLevel] = this.selectDataIndex;
    const resourceId = this.data[first]?.children[secondLevel]?.resourceId;
    if (this.rpm[resourceId]) {
      data = this.rpm[resourceId] || [];
    }
    return data;
  }

  @action
  public onSetValue = (values: any[]) => {
    const [first, secondLevel] = this.selectDataIndex;
    const resourceId = this.data[first]?.children[secondLevel]?.resourceId;
    this.rpm[resourceId] = values;
  };
}
