import { Button, Checkbox, Form, Input, Radio, Space } from 'antd';
import { values } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { FullModal } from '../../../../utils';
import styles from '../../index.less';
import type AddModel from './model';

@observer
export default class Add extends React.Component<{ store: AddModel }> {
  render() {
    const {
      visible,
      id,
      onCancel,
      rpm,
      onSetValue,
      getCheckboxGroupValue,
      onSelectSecondLevel,
      data,
      getSecondLevelChecked,
      onSelectFirst,
      getFirstChecked,
      getPermission,
      setSelectDataIndex,
      selectDataIndex,
      formRef,
      roleType,
      onSave,
    } = this.props.store;
    return (
      <FullModal
        visible={visible}
        onCancel={onCancel}
        title={id ? '编辑角色' : '添加角色'}
        operation={
          <Space>
            <Button
              onClick={onSave}
              type="primary"
            >
              保存
            </Button>
          </Space>
        }
      >
        <Form
          className={styles.form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          ref={formRef}
          initialValues={{ enable: '1' }}
        >
          <Form.Item
            label="角色名称"
            name="roleName"
            rules={[{ required: true }]}
          >
            <Input
              disabled={roleType === 1}
              placeholder="请输入角色名称"
              maxLength={50}
            />
          </Form.Item>
          <Form.Item
            label="备注"
            name="remark"
          >
            <Input.TextArea
              showCount={true}
              autoSize={{ minRows: 8 }}
              placeholder="请输入内容"
              maxLength={100}
            />
          </Form.Item>
          <Form.Item
            required={true}
            label="权限"
          >
            <div className={styles.set}>
              <div>
                {data?.map((item, index) => {
                  const indeterminate = getFirstChecked(item, 'indeterminate');
                  const checked = getFirstChecked(item, 'checked');
                  return (
                    <div
                      onClick={() => {
                        setSelectDataIndex(index, 0);
                      }}
                      className={styles.checkList}
                      key={item.resourceId}
                      style={selectDataIndex[0] === index ? { backgroundColor: 'rgb(234, 240, 247)' } : undefined}
                    >
                      <Checkbox
                        indeterminate={checked ? false : indeterminate}
                        checked={checked}
                        onChange={(e) => {
                          onSelectFirst(item, e.target.checked);
                        }}
                      >
                        {item.resourceName}
                      </Checkbox>
                    </div>
                  );
                })}
              </div>
              <div>
                {data[selectDataIndex[0]]?.children?.map((item, index) => {
                  const indeterminate = getSecondLevelChecked(item, 'indeterminate');
                  const checked = getSecondLevelChecked(item, 'checked');
                  return (
                    <div
                      onClick={() => {
                        setSelectDataIndex(index, 1);
                      }}
                      className={styles.checkList}
                      key={item.resourceId}
                      style={selectDataIndex[1] === index ? { backgroundColor: 'rgb(234, 240, 247)' } : undefined}
                    >
                      <Checkbox
                        indeterminate={checked ? false : indeterminate}
                        checked={checked}
                        onChange={(e) => {
                          onSelectSecondLevel(item, e.target.checked);
                        }}
                      >
                        {item.resourceName}
                      </Checkbox>
                    </div>
                  );
                })}
              </div>
              <div>
                <Checkbox.Group
                  onChange={(values) => {
                    onSetValue(values);
                  }}
                  value={getCheckboxGroupValue}
                  options={getPermission}
                />
              </div>
            </div>
          </Form.Item>
        </Form>
      </FullModal>
    );
  }
}
